import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

export interface ControlledInputProps {
  field: string;
  placeholder: string;
  control: any;
  hideLabel?: boolean;
  disabled?: boolean;
  type?: 'email' | 'password' | 'text' | 'number';
  formatFunction?: (value: string) => string;
  errors: any;
  style?: any;
  mask?: string;
  className?: string;
}

export const ControlledInput: FC<ControlledInputProps> = ({
  control,
  field,
  placeholder,
  hideLabel = false,
  disabled = false,
  type = 'text',
  formatFunction,
  errors,
  style,
  mask,
  className,
}) => (
  <Controller
    control={control}
    render={({ field: { onChange, onBlur, value } }) => (
      <div>
        {!hideLabel && (
          <label className="text-[#4a4a4a] mr-2.5">{placeholder}</label>
        )}
        {mask ? (
          <InputMask
            mask={mask}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            maskChar=""
            type={type}
            placeholder={placeholder}
            className={`${className} text-[#4a4a4a] w-[350px] h-[50px] pl-[24px] border shadow-[6px_4px_40px_rgba(0,0,0,0.05)] rounded-[5px] border-solid border-[#C5C5C5]`}
            style={style}
          />
        ) : (
          <input
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            onBlur={onBlur}
            value={formatFunction ? formatFunction(value) : value}
            onChange={e => onChange(e.target.value)}
            className={`${className} text-[#4a4a4a] w-[350px] h-[50px] pl-[24px] border shadow-[6px_4px_40px_rgba(0,0,0,0.05)] rounded-[5px] border-solid border-[#C5C5C5]`}
            style={style}
          />
        )}
        <p style={{ color: '#FF4E4E' }}>{errors[field]?.message}</p>
      </div>
    )}
    name={field}
    defaultValue=""
  />
);
