import { apiAuth, apiClient } from '@/services/api';

import { LoginProps } from '@/components/Login/schema';
import { toastError } from '@/services/toastError';
import { ResetProps } from '@/components/Reset/schema';

import { User } from '@/types/user';

export const auth = async ({
  email,
  password,
  loginAs,
}: LoginProps): Promise<User | undefined> => {
  try {
    const res = await apiAuth().post('/api/auth', {
      email,
      password,
      loginAs,
    });
    return res?.data;
  } catch (e: any) {
    const message = e?.response?.data.message;
    toastError(message);
  }
};

export const reset = async ({ email }: ResetProps) => {
  try {
    const res = await apiAuth().post('/api/reset', {
      email,
    });
    return res?.data;
  } catch (e: any) {
    const message = e?.response?.data.message;
    toastError(message);
  }
};

export const changePassword = async ({
  email,
  key,
  newPassword,
}: ResetProps) => {
  try {
    const res = await apiAuth().post('/api/changePassword', {
      email,
      key,
      newPassword,
    });
    return res?.data;
  } catch (e: any) {
    const message = e?.response?.data.message;
    toastError(message);
  }
};

export const firstAccess = async (password: string) => {
  try {
    const res = await apiClient().patch('/api/auth/firstAccess', {
      password,
    });
    return res?.data;
  } catch (e: any) {
    const message = e?.response?.data.message;
    toastError(message);
  }
};
